@import '../../../sass/mixin';

.header {
  display: flex;

  width: 100%;
  justify-content: space-between;
  align-items: center;

  @include mq(tab) {
    // justify-content: flex-end;
    margin-right: 20px;
  }

  @include mq(desk) {
    // flex-direction: row-reverse;
    justify-content: space-between;
    margin-right: 0;
  }
}

.navLi {
  display: none;
  @include mq(desk) {
    display: flex;
  }
}

.logo {
  font-size: 28px;
  font-family: 'Poppins Bold';

  @include mq(tab) {
    font-size: 32px;
  }
}

.logoT {
  color: var(--accent-color);
}

.link {
  text-align: center;

  padding: 20px 0;
  margin-left: 80px;

  font-size: 32px;
  font-weight: 500;

  transition: all var(--transition);

  @include mq(desk) {
    font-size: 20px;
  }
  &:hover,
  &:focus {
    color: var(--accent-color);
    text-decoration: underline solid var(--accent-color);
  }
}

.linkActive {
  font-weight: 700;
  color: var(--accent-color);
  text-decoration: underline solid var(--accent-color);
}

.auth {
  display: flex;
  padding-bottom: 40px;
  display: none;

  @include mq(desk) {
    padding: 0;
    display: flex;
  }

  @include mq(tab) {
    padding: 0;
    display: flex;
  }
}

.navAll {
  display: flex;
  align-items: center;

  @include mq(desk) {
    display: flex;
  }
}

.nav {
  display: none;

  @include mq(tab) {
    display: none;
  }

  @include mq(desk) {
    display: flex;
  }
}

.button {
  letter-spacing: 0.04em;
  color: black;
  font-size: 20px;
  text-align: center;
  padding: 8px 28px;
  border: 2px solid var(--accent-color);
  border-radius: 40px;

  @include mq(desk) {
    padding: 10px 28px;
  }
}

.buttonActive {
  color: white;
  background-color: var(--accent-color);
}

.buttonL {
  margin-right: 20px;
}

.account {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 8px 28px;
  font-size: 14px;
  border: 2px solid var(--accent-color);
  border-radius: 40px;

  transition: all var(--transition);

  color: white;
  background-color: var(--accent-color);
  @include mq(tab) {
    font-size: 16px;
    padding: 8px 28px;
  }
  &:hover,
  &:focus {
    background-color: var(--white);
    color: var(--font-color);
  }
}
.img {
  border-radius: 50%;
  margin-right: 14px;
}
