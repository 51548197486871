@font-face {
  font-family: 'Manrope Bold'; //700
  src: url('../fonts/Manrope-Bold.ttf');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Manrope Medium'; //500
  src: url('../fonts/Manrope-Medium.ttf');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Manrope Regular'; //400
  src: url('../fonts/Manrope-Regular.ttf');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Manrope SemiBold'; //600
  src: url('../fonts/Manrope-SemiBold.ttf');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins Bold'; //700
  src: url('../fonts/Poppins-Bold.ttf');
  font-style: normal;
  font-display: swap;
}
