@mixin mq($range) {
  $mobile: 480px;
  $tablet: 768px;
  $desktop: 1280px;
  @if $range == mob {
    @media (min-width: $mobile) {
      @content;
    }
  } @else if $range == mob-only {
    @media (max-width: #{$tablet - 0.02}) {
      @content;
    }
  } @else if $range == tab {
    @media (min-width: $tablet) {
      @content;
    }
  } @else if $range == tab-only {
    @media (min-width: $tablet) and (max-width: #{$desktop - 0.02}) {
      @content;
    }
  } @else if $range == desk {
    @media (min-width: $desktop) {
      @content;
    }
  } @else if $range == no-desk {
    @media (max-width: #{$desktop - 0.02}) {
      @content;
    }
  } @else if $range == retina {
    @media screen and (min-device-pixel-ratio: 2),
      screen and (-moz-min-device-pixel-ratio: 2),
      screen and (-o-min-device-pixel-ratio: 2/1),
      screen and (-webkit-min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      @content;
    }
  }
}
