:root {
  --transition: 250ms cubic-bezier(0.4, 0, 0.2, 1);

  --accent-color:#F59256;
  --font-color:#111111;
  --bg-color:#FDF7F2;
  --date-color:rgba(17, 17, 17, 0.6);

  --font-search-color:#535353;
  --font-news-color:#111321;

  --white: #ffffff;

  --gradient: linear-gradient(90deg, #FF634E 0%, #FFDF48 105.44%);
}
